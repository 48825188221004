import loadable from '@loadable/component';
import { HydrateWhenInViewport, Icon, PageContainer } from '@loveholidays/design-system';
import React, { useMemo, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

import { FooterTerms } from './FooterTerms';
import getFooterData from './getFooterData.gql';
import { Query } from '@AuroraTypes';
import { usePageLayoutContext } from '@Contexts/PageLayoutContext';
import { DesignSystemAsset } from '@DesignSystemComponents/DesignSystemAsset';

const LoadableTrustpilot = loadable(() => import('@loveholidays/design-system'), {
  resolveComponent: ({ Trustpilot }) => Trustpilot,
});

const LoadableFooterSection = loadable(() => import('./FooterSection'), {
  resolveComponent: ({ FooterSection }) => FooterSection,
});

const LoadableFooterPayments = loadable(() => import('./FooterPayments'), {
  resolveComponent: ({ FooterPayments }) => FooterPayments,
});

const LoadableFooterLogos = loadable(() => import('./FooterLogos'), {
  resolveComponent: ({ FooterLogos }) => FooterLogos,
});

const FooterImpl: React.FC = memo(() => {
  const { pathname: path } = useLocation();
  const [{ data }] = useQuery<Query>({
    query: getFooterData,
    variables: { path },
  });

  if (!data?.Content.page.footer) {
    return null;
  }

  const { variant, links, terms, footerUsps, trustpilot, supportedPayments } =
    data.Content.page.footer;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(
    () => (
      <HydrateWhenInViewport>
        <section
          sx={{ marginTop: 'xl', paddingY: '3xs' }}
          data-id="footer"
        >
          {trustpilot && (
            <PageContainer
              sx={{
                paddingY: variant === 'STANDARD' ? ['xl', null, '4xl'] : 'xl',
                overflowX: 'hidden',
              }}
            >
              <LoadableTrustpilot
                {...trustpilot}
                LogoComponent={
                  <DesignSystemAsset
                    type="Logo"
                    height={['28']}
                    sx={{
                      position: 'relative',
                      top: '5px',
                    }}
                    name="TrustPilot/LargeColoured"
                    fallback={
                      <strong sx={{ whiteSpace: 'nowrap', fontSize: 'xl' }}>
                        <Icon
                          name="Brands/Trustpilot"
                          size="28"
                          color="iconTrustpilot5star"
                        />
                        Trustpilot
                      </strong>
                    }
                  />
                }
                sx={{
                  'a.design-system': {
                    textDecoration: 'underline !important',
                  },
                }}
              />
            </PageContainer>
          )}
          <footer
            sx={{
              a: {
                textDecoration: 'none',
              },
              p: {
                fontSize: 's',
                marginBottom: 'xs',
              },
            }}
          >
            {links.length > 0 && (
              <LoadableFooterSection
                links={links}
                sx={{
                  marginBottom: 'xs',
                }}
              />
            )}
            {supportedPayments && supportedPayments.length > 0 && (
              <LoadableFooterPayments supportedPayments={supportedPayments} />
            )}
            {footerUsps && footerUsps?.length > 0 && <LoadableFooterLogos usps={footerUsps} />}
            <FooterTerms terms={terms} />
          </footer>
        </section>
      </HydrateWhenInViewport>
    ),
    [variant, links, footerUsps, terms, supportedPayments, trustpilot],
  );
});

const Footer: React.FC = () => {
  const { footerConfig } = usePageLayoutContext();

  if (footerConfig.hidden) {
    return null;
  }

  return <FooterImpl />;
};

// eslint-disable-next-line import/no-default-export
export default Footer;
