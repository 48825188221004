import { PageContainer } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { useAppContext } from '@Client/context/contexts';
import { HastNode } from '@Core/types';
import { hastToReact } from '@UX/Hast/Hast';
import { Separator } from '@UX/Separator/Separator';

type FooterTermsProps = {
  terms?: HastNode;
};

export const FooterTerms: React.FC<FooterTermsProps> = ({ terms }) => {
  const { site } = useAppContext();
  const { t } = useTranslation();

  return (
    <PageContainer
      sx={{
        marginTop: ['l', 'l', '2xl'],
        color: 'textDimmedmedium',
        a: {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }}
    >
      {terms &&
        hastToReact(terms, {
          hr: {
            Component: Separator,
            Props: {
              lineStyle: 'solid',
              color: 'strokeLightneutral',
              sx: {
                marginBottom: 'xs',
              },
            },
          },
        })}

      <p
        sx={{
          textAlign: 'center',
          marginTop: '4xl',
        }}
      >
        {t('footer.copyright', {
          year: String(new Date().getFullYear()),
          brand: site.brand,
        })}
      </p>
    </PageContainer>
  );
};
